import { useEffect, useLayoutEffect, useState } from 'react';
import '../ProjectSupportStation/css/bootstrap.min.css';
import '../ProjectSupportStation/css/owl.carousel.min.css';
import '../ProjectSupportStation/css/owl.theme.default.min.css';
import '../ProjectSupportStation/css/style.css';
import '../ProjectSupportStation/css/responsive.css';
import { useTranslation } from 'react-i18next';
import Header from '../ProjectSupportStation/Header';
import Footer from '../ProjectSupportStation/Footer';
import * as Services from '~/services/projectSupportStationService';
import routes from '~/config/routes';
import $ from 'jquery';
import Constants from '~/utils/Constants';
import { Helmet } from 'react-helmet';

const NUM_WORD_ENG = 7;
const NUM_CHAR_ZH = 28;

const HumanResourceStation = () => {
    const { t, i18n } = useTranslation();
    const REACT_APP_PREFIX = process.env.REACT_APP_PREFIX;
    // const { height, width } = useWindowDimensions();

    const _addScript = (source) => {
        let scripts = document.getElementsByTagName('script');
        for (var i = scripts.length; i--;) {
            if (
                scripts[i].src == `http://${window.location.host}${source}` ||
                scripts[i].src == `https://${window.location.host}${source}`
            )
                return;
        }

        const script = document.createElement('script');

        script.src = process.env.PUBLIC_URL + source;
        script.async = true;

        document.body.appendChild(script);
    };

    const _removeScript = (src) => {
        const scriptToRemove = document.querySelector(`script[src="${src}"]`);

        // Check if the script tag exists before attempting to remove it
        if (scriptToRemove) {
            // Remove the script tag from the DOM
            document.body.removeChild(scriptToRemove);
        } else {
            console.warn('Script tag not found');
        }
    };

    const [data, setData] = useState(null);
    const [optionIndex, setOptionIndex] = useState(0);
    const [options, setOptions] = useState([]);
    const [resourceListing, setResourceListing] = useState([]);
    const [resourceListingShow, setResourceListingShow] = useState([]);
    const [menu, setMenu] = useState([]);
    const [iconFooter, setIconFooter] = useState([]);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // windowHeight
    useLayoutEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [width, height]);

    // const menu = [
    //     {
    //         title: t('humanResourceStation.zhijiTalentStation'),
    //         href: '#introduction',
    //     },
    //     // {
    //     //     title: t('humanResourceStation.recruitmentManagementServices'),
    //     //     href: '#option',
    //     // },
    //     {
    //         title: t('humanResourceStation.options'),
    //         href: '#recruitment',
    //     },
    //     {
    //         title: t('humanResourceStation.recruitmentProcess'),
    //         href: '#advantages',
    //     },
    //     {
    //         title: t('humanResourceStation.resourceListing'),
    //         href: '#resource-listing',
    //     },
    //     {
    //         title: t('humanResourceStation.threeMajorBenefitsOfChoosing'),
    //         href: '#contact',
    //     },
    //     {
    //         title: t('humanResourceStation.contactUs'),
    //         href: '#faq',
    //     },
    // ];

    const logo = {
        image: require('~/assets/images/projectsupportstation/images/human-resource.png'),
        content: [
            {
                title: t('logoName.home'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${routes.home}`,
                image: require('~/assets/images/projectsupportstation/images/home.png'),
            },
            {
                title: t('logoName.supportStation'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${routes.projectSupportStation
                    }`,
                image: require('~/assets/images/projectsupportstation/images/support-station.png'),
            },
            {
                title: t('logoName.littleRedBook'),
                image: require('~/assets/images/projectsupportstation/images/xiao-hong-shu.png'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${routes.xiaoHongShu}`,
            },
            {
                title: t('logoName.budFund'),
                image: require('~/assets/images/projectsupportstation/images/bud.png'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${routes.bud}`,
            },
        ],
    };

    _addScript('/js/owl-carousel/owl.carousel.min.js');

    useEffect(() => {
        if (data) {
            _addScript('/js/custom.js');
            _addScript('/js/bootstrap.bundle.min.js');
        }
    }, [data]);

    useEffect(() => {
        _getData();
    }, [i18n]);

    const _getData = () => {
        Services.getHumanResource({ language: i18n.language }).then((res) => {
            setData(res);
            const menu = res?.menu
                ?.sort((a, b) => {
                    return a?.ordering - b?.ordering;
                })
                ?.map((value) => {
                    return {
                        title: value?.menu_text,
                        href: `#human-resource-station-${value?.ordering}`,
                        name: value?.section_name,
                        id: `human-resource-station-${value?.ordering}`,
                    };
                });

            setMenu(menu);
            let options = [];
            res?.option?.map((value) => {
                let option = {
                    category: value?.category,
                    content: [],
                };
                let tempContent = [];
                let title = null;
                value?.content?.map((item, index) => {
                    if (item?.color == 'icon' || item?.color == '0') {
                        if (tempContent.length > 0) {
                            option = {
                                ...option,
                                content: [
                                    ...option.content,
                                    {
                                        title: title,
                                        content: tempContent,
                                    },
                                ],
                            };
                            tempContent = [];
                        }
                        title = item;
                    } else {
                        tempContent.push(item);
                    }

                    if (index == value?.content?.length - 1) {
                        tempContent.push(item);
                        option = {
                            ...option,
                            content: [
                                ...option.content,
                                {
                                    title: title,
                                    content: tempContent,
                                },
                            ],
                        };
                        tempContent = [];
                    }
                });

                options = [...options, option];
            });

            setOptions(options);

            setResourceListing(res['resource-listing']);
            const resoureList = res['resource-listing']?.map((value) => {
                const content = value?.content?.map((item) => {
                    if (
                        item?.content?.split(i18n.language == 'en' ? ' ' : '')?.length >
                        (i18n.language == 'en' ? NUM_WORD_ENG : NUM_CHAR_ZH)
                    ) {
                        return {
                            ...item,
                            show: false,
                            cursor: true,
                        };
                    }

                    return { ...item, show: true };
                });

                return {
                    ...value,
                    content: content,
                };
            });

            // console.log(resoureList);

            setResourceListingShow(resoureList);

            const iconFooter = res?.footer?.map((value) => {
                let image = require('~/assets/images/projectsupportstation/images/whatsapp.png');
                if (value?.name?.toLocaleUpperCase() == 'WhatApps'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/whatsapp.png');
                }
                if (value?.name?.toLocaleUpperCase() == 'Facebook'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/facebook.png');
                }
                if (value?.name?.toLocaleUpperCase() == 'Instagram'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/instagram.png');
                }
                if (value?.name?.toLocaleUpperCase() == 'Linkedin'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/linkedin.png');
                }

                return {
                    icon: image,
                    href: value?.url,
                    title: value?.name,
                };
            });

            setIconFooter(iconFooter);
        });
    };

    const _onScroll = (event) => {
        console.log(event?.documentElement?.scrollTop);
    };

    const _onCLickContent = (index, subIndex, isShow) => {
        // const resoureList = resourceListing?.map((value, i) => {
        //     if (index == i) {
        //         const valueUpdate = value?.content?.map((item, iItem) => {
        //             if (subIndex == iItem) {
        //                 if (
        //                     resourceListingShow[i]?.content[iItem]?.show &&
        //                     item?.content?.split(i18n.language == 'en' ? ' ' : '')?.length >
        //                         (i18n.language == 'en' ? NUM_WORD_ENG : NUM_CHAR_ZH)
        //                 ) {
        //                     const contentArr = item?.content?.split(i18n.language == 'en' ? ' ' : '');
        //                     let content = '';
        //                     for (let index = 0; index < (i18n.language == 'en' ? NUM_WORD_ENG : NUM_CHAR_ZH); index++) {
        //                         content += i18n.language == 'en' ? ` ${contentArr[index]}` : `${contentArr[index]}`;
        //                     }
        //                     content = `${content?.trim()}...`;

        //                     return {
        //                         ...resourceListingShow[i]?.content[iItem],
        //                         content: content,
        //                         show: false,
        //                     };
        //                 }
        //                 return {
        //                     ...resourceListingShow[i]?.content[iItem],
        //                     ...resourceListing[i]?.content[iItem],
        //                     show: true,
        //                 };
        //             }

        //             return {
        //                 ...resourceListingShow[i]?.content[iItem],
        //                 ...resourceListingShow[i]?.content[iItem],
        //                 show: false,
        //             };
        //         });

        //         return { ...resourceListingShow[i], content: valueUpdate };
        //     }

        //     return resourceListingShow[i];
        // });

        // setResourceListingShow(resoureList);

        const resourceList = resourceListingShow.map((value, i) => {
            const content = value?.content?.map((item, iItem) => {
                if (subIndex == iItem && index == i) {
                    return {
                        ...item,
                        show: isShow,
                        //show: !item?.show,
                    };
                }

                return { ...item, show: false };
            });

            return {
                ...value,
                content: content,
            };

            return { ...value };
        });

        setResourceListingShow(resourceList);
    };

    return (
        <>
            <Helmet>
                <title>國內人才招聘及管理 - 職極GigGik</title>
                <meta name="og:title" content="國內人才招聘及管理 - 職極GigGik" />
                <meta name="og:url" content={`https://www.giggik.com/tc${routes.humanResourceStation}`} />
                <meta
                    name="description"
                    content="一站式國內人才招聘及管理服務，輕鬆減低一半人力成本開支，包辦人事管理，相關文書處理及出糧"
                />
                <link rel="canonical" href={`https://www.giggik.com/tc${routes.humanResourceStation}`} />
                <style>{`
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                    }
                    .font-size-18 {
                        font-size: 1.75rem;
                        font-weight: 700;
                        line-height: 1.2;
                        font-family: Noto Sans TC, serif;
                    }
                `}</style>
            </Helmet>
            {data && (
                <div className="wrapper">
                    <Header menu={menu} logo={logo} page={routes.humanResourceStation} />

                    <div className="banner">
                        <div className="container-fluid wave wave_banner spacing-0">
                            <div className="row container-fluid spacing-0">
                                <div className="col-12 spacing-0">
                                    <div className="banner-items owl-carousel owl-theme container-fluid spacing-0">
                                        {data?.banner?.map((value, index) => {
                                            return (
                                                <div
                                                    className={`item spacing-0 ${value.media_type == 'image' ? '' : 'video'
                                                        }`}
                                                    key={String(index)}
                                                >
                                                    {value.media_type == 'image' ? (
                                                        <img src={value?.url} alt={value?.title} />
                                                    ) : (
                                                        <video
                                                            className="videoContent"
                                                            width="100%"
                                                            height="100%"
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                objectFit: 'cover',
                                                            }}
                                                            // autoPlay={index == 0 ? true : false}
                                                            // playsInline
                                                            // muted="muted"
                                                            controls
                                                        >
                                                            <source src={value?.url} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className={`about bg-cream`} id={menu[0]?.id}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className={`col-lg-8 intro-content`}>
                                    <h1 className="mb-4 color-red">{data?.introduction[0]?.title}</h1>
                                    <p className="bold-500">{data?.introduction[0]?.content}</p>
                                </div>
                                <div className="col-lg-4 text-center ps-5">
                                    <img src={data?.introduction[0]?.image} alt={data?.introduction[0]?.title} />
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid wave wave_top ps-5 pe-5 mt-5">
                            {/* <div className="container row"> */}
                            <div className="col-12 text-center mt-5">
                                <h2 className="mb-4 color-red mt-5">
                                    {t('humanResourceStation.recruitmentManagementServices')}
                                </h2>
                            </div>
                            {/* </div> */}

                            <div
                                className={`container row ${data?.services?.length < 3 ? 'justify-content-around' : ''
                                    }`}
                            >
                                {data?.services?.map((value, index) => {
                                    return (
                                        <div className="col-lg-4" key={String(index)}>
                                            <div
                                                className="row"
                                                style={{
                                                    alignItems: 'center',
                                                    justifyContent:
                                                        width < Constants.WIDTH_MOBILE
                                                            ? 'center'
                                                            : index % 3 == 0
                                                                ? 'flex-start'
                                                                : index % 3 == 1
                                                                    ? 'center'
                                                                    : 'flex-end',
                                                }}
                                            >
                                                <img
                                                    src={require('~/assets/images/projectsupportstation/images/back_to_top.png')}
                                                    style={{ height: '100px', width: 'auto', zIndex: 3 }}
                                                />
                                                <div
                                                    className="pe-3 pt-3 pb-3 bg-white col-7 text-center border bold-500"
                                                    style={{
                                                        borderRadius: 9999,
                                                        marginTop: '15px',
                                                        marginLeft: '-50px',
                                                        paddingLeft: '28px',
                                                    }}
                                                >
                                                    {value?.content}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </section>

                    {/* <section className="service" id="option">
                        <div className="container-fluid wave wave_top">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h1 className="mb-4 color-red">
                                        {t('humanResourceStation.zhijiTalentStationOptions')}
                                    </h1>
                                </div>
                            </div>
                        </div>

                        <div className="row container ">
                            <div className="col-12">
                                <nav>
                                    <div
                                        className="nav nav-tabs justify-content-center mb-3"
                                        id="nav-tab"
                                        role="tablist"
                                    >
                                        {options.map((value, index) => {
                                            return (
                                                <button
                                                    key={String(index)}
                                                    className={`nav-link ${index == optionIndex ? 'active' : ''}`}
                                                    id={`nav-tab-${index}`}
                                                    data-bs-toggle="tab"
                                                    data-bs-target={`#nav-${index}`}
                                                    type="button"
                                                    role="tab"
                                                    aria-controls={`#nav-${index}`}
                                                    aria-selected={index == optionIndex}
                                                    onClick={setOptionIndex?.bind(this, index)}
                                                >
                                                    {value?.category}
                                                </button>
                                            );
                                        })}
                                    </div>
                                </nav>

                                <div className="tab-content ps-5 pe-5" id="nav-tabContent">
                                    {options?.map((value, index) => {
                                        return (
                                            <div
                                                key={String(index)}
                                                className={`tab-pane fade ${index == optionIndex ? 'active show' : ''}`}
                                                id={`nav-${index}`}
                                                role="tabpanel"
                                                aria-labelledby={`nav-tab-${index}`}
                                            >
                                                <div className="row">
                                                    {value?.content?.map((content, iContent) => {
                                                        return (
                                                            <div className="col-md-3 pb-3 pe-3" key={String(iContent)}>
                                                                <div
                                                                    className="row mb-4"
                                                                    style={{ alignItems: 'center', height: 'auto' }}
                                                                >
                                                                    <h3 className="color-red" style={{ width: 'auto' }}>
                                                                        {content?.title?.content}
                                                                    </h3>
                                                                    {content?.title?.color == 'icon' ? (
                                                                        <img
                                                                            src={require('~/assets/images/projectsupportstation/images/back_to_top.png')}
                                                                            style={{
                                                                                height: '30px',
                                                                                width: 'auto',
                                                                                zIndex: 3,
                                                                                marginLeft: '5px',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <div
                                                                            style={{ height: '30px', width: 'auto' }}
                                                                        ></div>
                                                                    )}
                                                                </div>
                                                                <ul className="check-circle">
                                                                    {content?.content?.map((item, iItem) => {
                                                                        return (
                                                                            <li
                                                                                className={`mb-3 ${item?.color}`}
                                                                                key={String(iItem)}
                                                                            >
                                                                                {item?.content}
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </section> */}

                    <section className="service" id={menu[1]?.id}>
                        <div className="container-fluid wave wave_top">
                            {/* <div className="row"> */}
                            <div className="col-12 text-center">
                                <h2 className="mb-4 color-red">{menu[1]?.name}</h2>
                            </div>
                            {/* </div> */}
                        </div>

                        <div
                            className={`row container ${data?.recruitment?.length < 4
                                ? width < Constants.WIDTH_MOBILE
                                    ? 'justify-content-center'
                                    : 'justify-content-around'
                                : ''
                                } mb-5`}
                        >
                            {data?.recruitment?.map((value, index) => {
                                let image = require('~/assets/images/projectsupportstation/images/Gig_Gik_Number_4.png');
                                switch (index + 1) {
                                    case 1:
                                        image = require('~/assets/images/projectsupportstation/images/Gig_Gik_Number_1.png');
                                        break;
                                    case 2:
                                        image = require('~/assets/images/projectsupportstation/images/Gig_Gik_Number_2.png');
                                        break;
                                    case 3:
                                        image = require('~/assets/images/projectsupportstation/images/Gig_Gik_Number_3.png');
                                        break;
                                    case 4:
                                        image = require('~/assets/images/projectsupportstation/images/Gig_Gik_Number_4.png');
                                        break;
                                }
                                return (
                                    <div className="col-lg-3 pb-3 pe-3 ps-3 " key={String(index)}>
                                        <div className="row h-auto spacing-0">
                                            <div
                                                className={`${width < Constants.WIDTH_MOBILE ? 'col-12' : 'col-10'
                                                    } d-flex justify-content-center spacing-0`}
                                            >
                                                <img
                                                    src={image}
                                                    style={{
                                                        height: '150px',
                                                        width: 'auto',
                                                    }}
                                                />
                                            </div>
                                            <div className="col-2 justify-content-center align-items-center icon-next-1">
                                                {index < data?.recruitment?.length - 1 && (
                                                    <img
                                                        src={require('~/assets/images/projectsupportstation/images/arrow-forward.png')}
                                                        style={{ width: '20px', height: 'auto' }}
                                                    />
                                                )}
                                            </div>
                                        </div>

                                        <div className={width < Constants.WIDTH_MOBILE ? 'col-12' : 'col-md-10'}>
                                            <p className="col-12 color-red mb-5 text-center font-size-18">
                                                {value?.title}
                                            </p>

                                            <ul className="check-circle">
                                                {value?.content?.map((content, iContent) => {
                                                    return (
                                                        <li
                                                            className={`${content?.color} mb-5 bold-500`}
                                                            key={String(iContent)}
                                                        >
                                                            {content?.content}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                            {value?.price?.map((price, iPrice) => {
                                                return (
                                                    <div
                                                        key={String(iPrice)}
                                                        className="p-2 bg-white text-center border mt-5 bold-500"
                                                        style={{
                                                            borderRadius: 9999,
                                                            height: 'auto',
                                                            width: 'auto',
                                                        }}
                                                    >
                                                        <p
                                                            className="mb-2 color-red font-size-18"
                                                            style={{ marginBottom: 0, padding: 0 }}
                                                        >
                                                            {price?.title}
                                                        </p>
                                                        {price?.content}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </section>

                    <section className="benefits bg-cream mb-5" id={menu[2]?.id}>
                        <div className="container-fluid  wave wave_top_cream">
                            {/* <div className="row"> */}
                            <div className="col-12 text-center">
                                <h2 className="mb-4 color-red">{menu[2]?.name}</h2>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className="row container mb-5">
                            {data?.advantages?.map((value, index) => {
                                return (
                                    <div className="col-md-4 mb-5" key={String(index)}>
                                        <div className="item">
                                            <img src={value?.image} alt={value?.title} />
                                            <strong className="color-orange d-block mb-3 mt-2">{value?.title}</strong>
                                            <ul className="check-circle">
                                                {value?.content?.map((item, iItem) => {
                                                    return (
                                                        <li
                                                            key={String(iItem)}
                                                            className={`${item?.color} mb-3 bold-500`}
                                                            dangerouslySetInnerHTML={{
                                                                __html: item?.content,
                                                            }}
                                                        ></li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </section>

                    <section className="service" id={menu[3]?.id} style={{ paddingTop: 0, paddingBottom: '80px' }}>
                        <div className="container-fluid wave wave_top">
                            {/* <div className="row"> */}
                            <div className="col-12 text-center">
                                <h2 className="mb-4 color-red">{menu[3]?.name}</h2>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className="row container">
                            {resourceListingShow?.map((value, index) => {
                                return (
                                    <div className="col-12" key={String(index)}>
                                        <div className="col-12 text-center mt-5 mb-5">
                                            <img src={value?.image_cat} style={{ width: 'auto', height: '150px' }} />
                                        </div>

                                        <div className="container-fluid row">
                                            <div
                                                className={`col-1 d-flex justify-content-center prev-${index} h-100`}
                                                style={{ marginTop: '120px', cursor: 'pointer' }}
                                            >
                                                <img
                                                    src={require('~/assets/images/projectsupportstation/images/arrow_prev.png')}
                                                    style={{ width: '20px', height: '20px' }}
                                                />
                                            </div>
                                            <div className="col-10">
                                                <div className={`owl-carousel-${index} owl-carousel owl-theme ps-5`}>
                                                    {value?.content?.map((content, iContent) => {
                                                        return (
                                                            <div
                                                                // onMouseOver={() =>
                                                                //     _onCLickContent(index, iContent, true)
                                                                // }
                                                                // onMouseOut={() =>
                                                                //     _onCLickContent(index, iContent, false)
                                                                // }
                                                                className="item row justify-content-between resource"
                                                                key={String(iContent)}
                                                            >
                                                                <div
                                                                    className="border p-3 bg-white mb-5"
                                                                    style={{
                                                                        borderRadius: '20px',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'center',
                                                                        overflow: 'hidden',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={content?.image}
                                                                        alt={content?.title}
                                                                        style={{
                                                                            height: '100px',
                                                                            width: 'auto',
                                                                            overflow: 'hidden',
                                                                        }}
                                                                    />
                                                                    <div
                                                                        style={{
                                                                            minHeight: '200px',
                                                                            width: '100%',
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            justifyContent: 'space-between',
                                                                        }}
                                                                    >
                                                                        <div className="col-12">
                                                                            <h3 className="color-red mt-3 mb-3">
                                                                                {content?.title}
                                                                            </h3>
                                                                            <div
                                                                                className={`text-max-line text-max-line-p bold-500`}
                                                                                style={{ fontSize: '16px' }}
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: content?.content,
                                                                                }}
                                                                            ></div>
                                                                        </div>
                                                                        <div className="row col-12 mt-3 justify-content-between h-auto align-items-center">
                                                                            <div className="col-8 d-flex align-items-center">
                                                                                <img
                                                                                    src={require('~/assets/images/projectsupportstation/images/GigGik_Bullet_Point.png')}
                                                                                    style={{
                                                                                        width: 'auto',
                                                                                        height: '24px',
                                                                                    }}
                                                                                />
                                                                                <p
                                                                                    className="w-auto spacing-0"
                                                                                    style={{
                                                                                        paddingBottom: 0,
                                                                                        fontSize: '12px',
                                                                                        marginLeft: '5px',
                                                                                    }}
                                                                                >
                                                                                    {content?.created_by}
                                                                                </p>
                                                                            </div>
                                                                            <div
                                                                                className="col-4 text-end spacing-0"
                                                                                style={{
                                                                                    fontSize:
                                                                                        width < 375 ? '18px' : '12px',
                                                                                    marginRight: '-7px',
                                                                                }}
                                                                            >
                                                                                {content?.create_date}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <div
                                                className={`col-1 d-flex justify-content-center next-${index} h-100`}
                                                style={{ marginTop: '120px', cursor: 'pointer' }}
                                            >
                                                <img
                                                    src={require('~/assets/images/projectsupportstation/images/arrow_next.png')}
                                                    style={{ width: '20px', height: '20px' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </section>

                    <section className="partial-contact bg-cover py-5" id={menu[4]?.id}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <a
                                        href="https://sys.giggik.com/index.php?c=Form&a=job&source_id=13"
                                        target="_blank"
                                        className={`btn btn-default btn-large ${width < 600 ? 'mb-2' : ''}`}
                                    >
                                        {t('projectSupportStation.contactUs')}
                                    </a>
                                    <img
                                        src={require('~/assets/images/projectsupportstation/images/Gig_Gik.png')}
                                        className="Gig_Gik"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="faq" id={menu[5]?.id}>
                        <div className="container-fluid wave wave_bottom">
                            <div className="container content-faq">
                                {/* <div className="row"> */}
                                <div className="col-12 text-center">
                                    <h2 className="mb-4 color-red">{menu[5]?.name}</h2>
                                </div>
                                {/* </div> */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="accordion accordion-flush" id="accordionFlushExample">
                                            {data?.faq?.map((value, index) => {
                                                return (
                                                    <div className="accordion-item" key={String(index)}>
                                                        <div
                                                            className="accordion-header"
                                                            style={{ marginBottom: '30px' }}
                                                        >
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#flush-collapse${index}`}
                                                                aria-expanded="false"
                                                                aria-controls={`flush-collapse${index}`}
                                                            // style={{ backgroundColor: '#f8c03d' }}
                                                            >
                                                                <img
                                                                    src={require('~/assets/images/projectsupportstation/images/Gig_Gik_question.png')}
                                                                    className="icon"
                                                                />
                                                                <h3>{value?.category}</h3>
                                                            </button>
                                                        </div>

                                                        <div
                                                            id={`flush-collapse${index}`}
                                                            className="accordion-collapse collapse ps-5"
                                                            data-bs-parent="#accordionFlushExample"
                                                        >
                                                            {value?.question?.map((question, iQuestion) => {
                                                                return (
                                                                    <div
                                                                        key={String(iQuestion)}
                                                                        style={{ marginBottom: '30px' }}
                                                                    >
                                                                        <button
                                                                            className="accordion-button spacing-0"
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#flush-sub-collapse${index}${iQuestion}`}
                                                                            aria-expanded="false"
                                                                            aria-controls={`flush-sub-collapse${index}${iQuestion}`}
                                                                        >
                                                                            {question?.question}
                                                                        </button>
                                                                        <div
                                                                            id={`flush-sub-collapse${index}${iQuestion}`}
                                                                            className="accordion-collapse collapse"
                                                                            data-bs-parent={`#flush-collapse${index}`}
                                                                        >
                                                                            <div className="accordion-body">
                                                                                {question?.answer}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 row mt-3 align-items-center">
                                                                            <div className="col-1 border-bottom-red-3"></div>
                                                                            <div className="col-11 border-bottom"></div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer logo={data?.sticky_logo[0]} iconFooter={iconFooter} />
                </div>
            )}
        </>
    );
};

export default HumanResourceStation;
